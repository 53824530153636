const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
const GET_ORGANIZATIONS_COMPLETED = 'GET_ORGANIZATIONS_COMPLETED';
const GET_ORGANIZATIONS_FAILED = 'GET_ORGANIZATIONS_FAILED';
const GET_ORGANIZATIONS_SIMPLE = 'GET_ORGANIZATIONS_SIMPLE';
const GET_ORGANIZATIONS_SIMPLE_COMPLETED = 'GET_ORGANIZATIONS_SIMPLE_COMPLETED';
const GET_ORGANIZATIONS_SIMPLE_FAILED = 'GET_ORGANIZATIONS_SIMPLE_FAILED';
const GET_SINGLE_ORGANIZATION = 'GET_SINGLE_ORGANIZATION';
const GET_SINGLE_ORGANIZATION_COMPLETED = 'GET_SINGLE_ORGANIZATION_COMPLETED';
const GET_SINGLE_ORGANIZATION_FAILED = 'GET_SINGLE_ORGANIZATION_FAILED';
const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
const CREATE_ORGANIZATION_COMPLETED = 'CREATE_ORGANIZATION_COMPLETED';
const CREATE_ORGANIZATION_FAILED = 'CREATE_ORGANIZATION_FAILED';
const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
const UPDATE_ORGANIZATION_COMPLETED = 'UPDATE_ORGANIZATION_COMPLETED';
const UPDATE_ORGANIZATION_FAILED = 'UPDATE_ORGANIZATION_FAILED';
const GET_ORGANIZATIONS_EXT = 'GET_ORGANIZATIONS_EXT';
const GET_ORGANIZATIONS_EXT_COMPLETED = 'GET_ORGANIZATIONS_EXT_COMPLETED';
const GET_ORGANIZATIONS_EXT_FAILED = 'GET_ORGANIZATIONS_EXT_FAILED';
const GET_REPORT_OIR = 'GET_REPORT_OIR';
const GET_REPORT_OIR_COMPLETED = 'GET_REPORT_OIR_COMPLETED';
const GET_REPORT_OIR_FAILED = 'GET_REPORT_OIR_FAILED';

export default {
  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_COMPLETED,
  GET_ORGANIZATIONS_FAILED,
  GET_ORGANIZATIONS_SIMPLE,
  GET_ORGANIZATIONS_SIMPLE_COMPLETED,
  GET_ORGANIZATIONS_SIMPLE_FAILED,
  GET_SINGLE_ORGANIZATION,
  GET_SINGLE_ORGANIZATION_COMPLETED,
  GET_SINGLE_ORGANIZATION_FAILED,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_COMPLETED,
  CREATE_ORGANIZATION_FAILED,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_COMPLETED,
  UPDATE_ORGANIZATION_FAILED,
  GET_ORGANIZATIONS_EXT,
  GET_ORGANIZATIONS_EXT_COMPLETED,
  GET_ORGANIZATIONS_EXT_FAILED,
  GET_REPORT_OIR,
  GET_REPORT_OIR_COMPLETED,
  GET_REPORT_OIR_FAILED,
};
