import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBan, faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import { casefilesOperations } from '../../../../redux/ducks/casefiles';
import { organizationsOperations } from '../../../../redux/ducks/organizations';
import { doctorsOperations } from '../../../../redux/ducks/doctors';

const mockDoctors = [];

function CasefileView({ getDoctorsSimple, getOrganizationsSimple, getSingleCasefile, updateCasefile, auth, }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [casefile, setCasefile] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [regions, setRegions] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');

  useEffect(() => {
    if (!auth?.update.includes('CASEFILE_UPDATE')) {
      setTimeout(() => {
        navigate('/');
      }, 10);
    } else {
      const splitUrl = location.pathname.split('/casefile/')[1];
      if (splitUrl !== 'new') {
        getCasefileData(splitUrl);
      }
    }
    getOrganizationsSimple()
      .then((res => {
        const { organizations, regions, } = res.data.data;
        setOrganizations(organizations);
        setRegions(regions);
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }, []);

  const getCasefileData = url => {
    const id = url.split('/')[0];
    getSingleCasefile(id)
      .then((res => {
        setCasefile(res.data.data);
        const { doctor, } = res.data.data;
        if (doctor && doctor.person) {
          const { id, } = doctor;
          const { firstName, lastName, } = doctor.person;
          setSelectedDoctor({
            id,
            value: `${lastName} ${firstName}`,
          });
        }
        if (doctor && doctor.organization) {
          setSelectedSupplier(doctor.organization);
        }
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  };

  const goBack = () => {
    const { state, } = location;
    if (state) {
      const { state: { from: { search, pathname, }, }, } = location;
      navigate(`${pathname}${search}`);
    } else {
      navigate('/casefile');
    }
  };

  const goEdit = () => {
    const { id, } = casefile;
    navigate(`/casefile/${id}/edit`);
  };

  if (casefile === null) return <Spinner />;

  const {
    id = '',
    createTs = '',
    active = false,
    doctor: {
      person: {
        firstName: firstNameDoctor = '',
        lastName: lastNameDoctor = '',
      },
    },
    patient: {
      person: {
        firstName: firstNamePatient = '',
        lastName: lastNamePatient = '',
      },
    },
    disease: { label = '', },
    tsfEntities: patientFiles = [],
  } = casefile;

  const selectDoctor = selected => {
    setSelectedDoctor(selected || '');
  };

  const handleSupplierSelect = selected => {
    setSelectedSupplier(selected || '');
    if (selected) {
      if (selected?.id) {
        getDoctorsSimple(selected.id)
          .then((res => {
            setDoctors(res.data.data);
            setSelectedDoctor('');
          }))
          .catch((err => {
            console.log('err: ', err);
          }));
      }
    }
  };

  const saveChanges = () => {
    const payload = {
      id: casefile.id,
      doctor: { id: selectedDoctor ? selectedDoctor.id : '', },
    };
    updateCasefile(payload).then(res => {
    }).catch(err => {
      console.log(err);
    });
  };

  const calcDisabled = () => {
    let disabled = false;

    if (selectedSupplier === '' || selectedSupplier === null) { disabled = true; }
    if (selectedDoctor === '' || selectedDoctor === null) { disabled = true; }
    return disabled;
  };

  const saveDisabled = calcDisabled();
  return (
    <Container fluid className="d-flex ">
      <Row className="w-100 m-0 justify-content-center">
        <Col lg={10}>
          <Card className="mt-3">
            <CardHeader>
              <h3 className="mb-0">Editare Dosar</h3>
            </CardHeader>
            <CardBody className="p-5">
              <Row>
                <Col md={12} className="mb-3">
                  <FormGroup>
                    <Label className="font-weight-bold">Creat la data:</Label>
                    <Input
                      disabled
                      type="text"
                      name="createTs"
                      value={moment(createTs).format('DD-MM-YYYY  HH:mm:ss')}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mb-3">
                  <FormGroup>
                    <Label className="font-weight-bold">Beneficiar:</Label>
                    <Input
                      disabled
                      type="text"
                      name="patientName"
                      value={`${firstNamePatient} ${lastNamePatient}`}
                    />
                  </FormGroup>
                </Col>

                <Col md={12} className="mb-3">
                  <FormGroup>
                    <Label className="font-weight-bold">Program:</Label>
                    <Input
                      disabled
                      type="text"
                      name="label"
                      value={label}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mb-3">
                  <Label className="font-weight-bold">Furnizor:</Label>
                  <FormGroup>
                    <Select
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.value || option.label}
                      value={selectedSupplier}
                      className={selectedSupplier ? 'select-valid' : 'select-invalid'}
                      classNamePrefix="rolesSelect"
                      placeholder=""
                      isClearable
                      name="suppliers"
                      options={organizations}
                      onChange={handleSupplierSelect}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mb-3">
                  <Label className="font-weight-bold">Personal furnizor:</Label>
                  <FormGroup>
                    <Select
                      value={selectedDoctor}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.value}
                      className={selectedDoctor ? 'select-valid' : 'select-invalid'}
                      classNamePrefix="rolesSelect"
                      placeholder=""
                      isClearable
                      name="selectedDoctor"
                      options={doctors}
                      onChange={selectDoctor}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6} />
                <Col md={6} className="text-right">
                  <Button
                    onClick={goBack}
                    color="secondary"
                  >
                    <FontAwesomeIcon icon={faBan} />
                    <span className="ml-1">Anulare</span>
                  </Button>
                  <NavLink to={`/casefile/${id}/edit`}>
                    <Button
                      disabled={saveDisabled}
                      onClick={saveDisabled ? () => {} : saveChanges}
                      color="primary"
                      className="ml-1"
                    >
                      <FontAwesomeIcon icon={faSave} />
                      <span className="ml-1">Salvare</span>
                    </Button>
                  </NavLink>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
const mapStateToProps = state => ({
  auth: state.auth,
  casefiles: state.casefiles.casefiles,
  selectedCasefile: state.casefiles.selectedCasefile,
});

const mapDispatchToProps = dispatch => ({
  getSingleCasefile: id => dispatch(casefilesOperations.getSingleCasefile(id)),
  updateCasefile: data => dispatch(casefilesOperations.updateCasefile(data)),
  getOrganizationsSimple: () => dispatch(organizationsOperations.getOrganizationsSimple()),
  getDoctorsSimple: orgId => dispatch(doctorsOperations.getDoctorsSimple(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CasefileView);
