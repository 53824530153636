import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import CasefilesForPatientTableRow from './CasefilesForPatientTableRow';
import PatientCasefileTable from './PatientCasefileTable';

function CasefilesForPatientTable({ casefile, tableData, openModal, auth, }) {
  const domPatientFiles = tableData.map(elem => (
    <CasefilesForPatientTableRow
      casefile={casefile}
      canActivate={auth?.activate.includes('TSFENTITY_ACTIVATE') || false}
      canEdit={auth?.update.includes('TSFENTITY_UPDATE') || false}
      openModal={openModal}
      key={elem.id}
      data={elem}
    />
  ));

  return (
    <div id="entities" className="table-responsive-xl">
      <table aria-describedby="page-heading" className="table">
        <thead>
          <tr>
            <th scope="col">
              <span>Detalii</span>
            </th>
            <th scope="col">
              <span>Creat la data</span>
            </th>
            <th scope="col">
              <span>Modificat la data</span>
            </th>
            <th className="text-center" scope="col">
              <span>Validat OIR</span>
            </th>
            <th className="text-center" scope="col">
              <span>Stare</span>
            </th>
            <th scope="col">
              <span>Status</span>
            </th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          { domPatientFiles}
        </tbody>
      </table>
    </div>
  );
}

export default CasefilesForPatientTable;
