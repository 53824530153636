import types from './types';

const getOrganizations = url => ({
  type: types.GET_ORGANIZATIONS,
  async: true,
  generalFetching: true,
  payload: {
    path: url,
    method: 'GET',
  },
});

const getOrganizationsSimple = () => ({
  type: types.GET_ORGANIZATIONS_SIMPLE,
  async: true,
  generalFetching: true,
  payload: {
    path: '/organizations_lite',
    method: 'GET',
  },
});

const getReportOir = ({ county, label, region, year, month, report, }) => ({
  type: types.GET_REPORT_OIR,
  async: true,
  generalFetching: true,
  payload: {
    path: `/reportoir?county=${county}&label=${label}&region=${region}&year=${year}&month=${month}&report=${report}`,
    method: 'GET',
    responseType: 'blob',
  },
});

const getOrganizationsExt = () => ({
  type: types.GET_ORGANIZATIONS_EXT,
  async: true,
  generalFetching: true,
  payload: {
    path: '/organizations_lite_ext',
    method: 'GET',
  },
});

const getSingleOrganization = id => ({
  type: types.GET_SINGLE_ORGANIZATION,
  async: true,
  generalFetching: true,
  payload: {
    path: `/organizations/${id}`,
    method: 'GET',
  },
});

const createOrganization = data => ({
  type: types.CREATE_ORGANIZATION,
  async: true,
  generalFetching: true,
  payload: {
    path: '/organizations',
    method: 'POST',
    body: data,
  },
});

const updateOrganization = data => ({
  type: types.UPDATE_ORGANIZATION,
  async: true,
  generalFetching: true,
  payload: {
    path: `/organizations/${data.id}`,
    method: 'PUT',
    body: data,
  },
});

export default {
  getOrganizations,
  createOrganization,
  getSingleOrganization,
  getOrganizationsSimple,
  updateOrganization,
  getOrganizationsExt,
  getReportOir,
};
