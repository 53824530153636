/* eslint-disable no-undef */
import actions from './actions';

// const {
//   createOrganization,
// } = actions;

const getOrganizations = url => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getOrganizations(url))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getOrganizationsSimple = () => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getOrganizationsSimple())
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getOrganizationsExt = () => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getOrganizationsExt())
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getSingleOrganization = id => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getSingleOrganization(id))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getReportOir = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getReportOir(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const createOrganization = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.createOrganization(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const updateOrganization = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.updateOrganization(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

export default {
  createOrganization,
  updateOrganization,
  getOrganizations,
  getOrganizationsSimple,
  getSingleOrganization,
  getOrganizationsExt,
  getReportOir,
};
