import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row, UncontrolledAlert
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCalendarAlt, faSave, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import ro from 'date-fns/locale/ro'; // the locale you want
import PatientAddresses from '../../Doctors/components/DoctorAddresses';
import { calcCnpRelatedValues } from './PatientForm.helper';
import {
  vulnerableCategories,
  disadvantagedCategories,
  populationCategories,
  educationCategories
} from '../patients.helper';

import 'react-datepicker/dist/react-datepicker.css';
import { patientsOperations } from '../../../../redux/ducks/patients';

registerLocale('ro', ro); // register it with the name you want

const phoneRegExp = /^\d{10}$/;
const emailRegExp = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

class PatientForm extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      lastName: '',
      lastNameError: false,
      firstName: '',
      firstNameError: false,
      cnp: '',
      cnpError: [],
      birthDate: '',
      identityCardSeries: '',
      identityCardNumber: '',
      gender: null,
      habitat: null,
      populationCategorySelected: null,
      populationCategoryError: true,
      populationCategoryOptions: populationCategories,
      educationSelected: null,
      populationError: true,
      educationOptions: educationCategories,
      disadvantagedSelected: null,
      disadvantagedError: true,
      disadvantagedOptions: disadvantagedCategories,
      vulnerableSelected: null,
      vulnerableOptions: vulnerableCategories,
      mobilizationDate: '',
      disadvantaged: false,
      vulnerability: false,
      eligible: false,
      phone: '',
      phoneError: false,
      email: '',
      emailError: false,
      files: [],
      addresses: [],
      contacts: [],
      saving: false,
      error: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.handleGenderClick = this.handleGenderClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleContactsChange = this.handleContactsChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.mobilizationDateChange = this.mobilizationDateChange.bind(this);
    this.prefillFields = this.prefillFields.bind(this);
    this.handleActiveCheck = this.handleActiveCheck.bind(this);
    this.getSinglePatient = this.getSinglePatient.bind(this);
    this.handleHabitat = this.handleHabitat.bind(this);
    this.handleCnpChange = this.handleCnpChange.bind(this);
    this.validateCnp = this.validateCnp.bind(this);
    this.birthDateChange = this.birthDateChange.bind(this);
    this.handleSelectDisadvantaged = this.handleSelectDisadvantaged.bind(this);
    this.calcDisabled = this.calcDisabled.bind(this);
    this.handleVulnerable = this.handleVulnerable.bind(this);
    this.handleCreateErrors = this.handleCreateErrors.bind(this);
  }

  componentDidMount() {
    const { location, auth, navigate, } = this.props;
    const splitUrl = location.pathname.split('/patient/')[1];

    if (!auth?.update.includes('PATIENT_UPDATE')) {
      setTimeout(() => {
        navigate('/patient');
      }, 10);
    }

    if (splitUrl !== 'new') {
      // console.log(splitUrl);
      this.getSinglePatient(splitUrl);
    }
  }

  getSinglePatient(url) {
    const { navigate, getSinglePatient, } = this.props;
    const id = url.split('/')[0];
    getSinglePatient(id)
      .then((res => {
        // console.log(res.data.data);
        this.prefillFields(res.data.data);
      }))
      .catch((err => {
        console.log('err: ', err);
        setTimeout(() => { navigate('/patient'); }, 100);
      }));
  }

  prefillFields(data) {
    const {
      active,
      id, mobilizationDate: mobilizationDateSaved, disadvantaged, vulnerability, eligible,
      populationCategory, disadvantages = [], vulnerabilities = [],
      person: {
        lastName, firstName, cnp,
        birthDate, gender = '', habitat = '', addresses = [], contacts = [], education, identityCardSeries = '', identityCardNumber = '',
      },
    } = data;

    if (!active) {
      const { navigate, } = this.props;
      setTimeout(() => { navigate(`/patient/${id}/view`); }, 50);
    }

    this.setState({
      id,
      disadvantaged,
      vulnerability,
      identityCardSeries,
      identityCardNumber,
      eligible,
      lastName,
      firstName,
      cnp,
      // birthDate: birthDate ? moment(birthDate).format('DD-MM-YYYY') : '',
      birthDate: moment(birthDate).toDate(),
      gender,
      habitat,
      addresses,
      phone: calcPhone(contacts),
      email: calcEmail(contacts),
      contacts, // TODO - populate phone, email fields
      mobilizationDate: moment(mobilizationDateSaved).toDate(),
      educationSelected: education || null,
      populationError: !education,
      populationCategorySelected: populationCategory || null,
      populationCategoryError: !populationCategory,
      disadvantagedSelected: disadvantages || null,
      disadvantagedError: typeof disadvantages === 'undefined' || disadvantages.length === 0,
      vulnerableSelected: vulnerabilities || null,
      editableData: false,
    }, () => {
      const { phone, email, } = this.state;
      if (phone !== '') {
        this.validateField('phone', phone);
      }
      if (email !== '') {
        this.validateField('email', email);
      }
    });
  }

  handleCreateErrors(data) {
    if (data) {
      const errorMessage = data?.message || 'Operatiunea nu a putut fi efectuata!';
      this.setState({ error: errorMessage, }, () => {
        setTimeout(() => {
          this.setState({ error: null, });
        }, 3000);
      });
    }
  }

  handleSubmit() {
    this.setState({ saving: true, });
    const { updatePatient, createPatient, } = this.props;
    const {
      id, mobilizationDate,
      disadvantaged, vulnerability, eligible, identityCardSeries, identityCardNumber,
      firstName, lastName, cnp, birthDate, gender, addresses, phone, email,
      habitat, populationCategorySelected, educationSelected, disadvantagedSelected, vulnerableSelected,
    } = this.state;

    const contacts = [];
    if (phone !== '') {
      contacts.push({
        active: false,
        contactType: { value: 'TELEFON', label: 'TELEFON', },
        label: phone,
      });
    }

    if (email !== '') {
      contacts.push({
        active: false,
        contactType: { value: 'EMAIL', label: 'EMAIL', },
        label: email,
      });
    }

    const payload = {
      id,
      eligible,
      mobilizationDate: moment(mobilizationDate).format('YYYY-MM-DD'),
      person: {
        firstName,
        lastName,
        cnp,
        identityCardSeries,
        identityCardNumber,
        gender,
        birthDate: birthDate ? moment(birthDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
        habitat,
        addresses,
        contacts,
        education: educationSelected,
      },
      populationCategory: populationCategorySelected,
      disadvantaged,
      vulnerability,
      // education: educationSelected,
      disadvantages: disadvantagedSelected,
      vulnerabilities: vulnerableSelected,
    };

    if (id === null) {
      delete payload.id;
      createPatient(payload).then(res => {
        const { navigate, } = this.props;
        setTimeout(() => {
          navigate(`/patient/${res.data.data.id}/view`);
        }, 300);
      }).catch(error => {
        if (error && error.data && error.data.message) {
          if (error.data.message.indexOf('CNP invalid') !== -1) {
            this.setState({ cnpError: ['CNP-ul nu este valid'], });
          }
          this.handleCreateErrors(error.data || null);
        }
        this.setState({ saving: false, });
      });
    } else {
      updatePatient(payload).then(() => {
        const { navigate, } = this.props;
        setTimeout(() => {
          navigate(`/patient/${payload.id}/view`);
        }, 300);
      }).catch(error => {
        this.setState({ saving: false, });
        console.log('err: ', error);
        this.handleCreateErrors(error.data);
      });
    }
  }

  handleAddressChange(value, type) {
    const { addresses, } = this.state;
    if (type === 'remove') {
      const newAddresses = addresses.filter(elem => elem.id !== value.id);
      this.setState({ addresses: newAddresses, }, () => {
        this.handleHabitat();
      });
    } else if (value.id === null) {
      value.id = `${value?.city?.value || ''}${value?.streetNo || ''}${value?.apartmentNo || ''}`;
      const newAddresses = [...addresses, value];
      this.setState({ addresses: newAddresses, }, () => {
        this.handleHabitat();
      });
    } else {
      const { id, } = value;
      const newAddresses = addresses.map(el => {
        if (el.id === id) {
          return { ...el, ...value, };
        }
        return el;
      });
      this.setState({ addresses: newAddresses, }, () => {
        this.handleHabitat();
      });
    }
  }

  handleHabitat() {
    const { addresses = [], } = this.state;
    if (addresses.length > 0 && addresses[0] && typeof addresses[0].city !== 'undefined') {
      this.setState({ habitat: addresses[0]?.city.isUrban ? 'Urban' : 'Rural', }, () => {
        this.handleVulnerable();
      });
    } else {
      this.setState({ habitat: null, }, () => {
        this.handleVulnerable();
      });
    }
  }

  onDrop(uploaded) {
    const { files, } = this.state;
    this.setState({ files: [...files, ...uploaded], });
  }

  handleContactsChange(value) {
  }

  validateField(name, value) {
    switch (name) {
      case 'firstName':
      case 'lastName': { this.setState({ [`${name}Error`]: value === '', }); }
        break;
      case 'phone': {
        phoneRegExp.test(value);
        this.setState({ [`${name}Error`]: (value !== '' && phoneRegExp.test(value) === false), });
      }
        break;
      case 'email': {
        emailRegExp.test(value);
        this.setState({ [`${name}Error`]: (value !== '' && emailRegExp.test(value) === false), });
      }
        break;
      case 'educationSelected':
      case 'disadvantagedSelected':
      case 'populationCategorySelected': { this.setState({ [`${name}Error`]: value === null, }); }
        break;
      case 'cnp': {
        calcCnpRelatedValues(value, this);
      }
        break;
      case 'identityCardSeries': { this.setState({ [`${name}Error`]: value.length > 2, }); }
        break;
      case 'identityCardNumber': { this.setState({ [`${name}Error`]: value.length > 6, }); }
        break;
    }
  }

  handleInputChange(e) {
    const { name, value, required, } = e.target;
    this.setState({ [name]: value, }, () => {
      if (required) {
        this.validateField(name, value);
      }
    });
  }

  handleCnpChange(e) {
    const { value, } = e.target;
    this.setState({ cnp: value, }, () => {
      this.validateCnp(value);
    });
  }

  validateCnp(cnpValue) {
    // validate extra rules
    // console.log('validate cnp: ', cnpValue);
    const regExp = /[a-zA-Z]/g;
    const cnpErrors = [];

    if (cnpValue.length > 0 && cnpValue.length !== 13) {
      cnpErrors.push('CNP-ul este format din 13 cifre!');
    }
    if (regExp.test(cnpValue)) {
      cnpErrors.push('Sunt permise doar cifre!');
    }
    const mask = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    let sum = 0;
    for (let i = 0; i < cnpValue.length - 1; i++) {
      sum += parseInt(cnpValue[i]) * mask[i];
    }
    const mod = (sum % 11 == 10) ? 1 : sum % 11;
    const isValid = (mod == cnpValue[mask.length]);
    // console.log('isValid: ', isValid);
    if (!isValid) {
      cnpErrors.push('CNP-ul nu este valid!');
    } else if (cnpValue[0] == 1 || cnpValue[0] == 3 || cnpValue[0] == 5) {
      this.setState({
        gender: 'M',
        eligible: false,
      });
    // } else if (cnpValue[0] == 2 || cnpValue[0] == 4 || cnpValue[0] == 6 || cnpValue[0] == 8) {
    } else if (cnpValue[0] == 2 || cnpValue[0] == 4 || cnpValue[0] == 6) {
      this.setState({ gender: 'F', }, () => {
        let century = null; const year = 0; const month = 0; const
          day = 0;
        let dataNasterii;
        if (cnpValue[0] == 1 || cnpValue[0] == 2) {
          century = '19';
        }
        if (cnpValue[0] == 3 || cnpValue[0] == 4) {
          century = '18';
        }
        if (cnpValue[0] == 5 || cnpValue[0] == 6) {
          century = '20';
        }
        if (century != null) {
          // dataNasterii = (`${cnpValue[5]}${cnpValue[6]}/${cnpValue[3]}${cnpValue[4]}/${century}${cnpValue[1]}${cnpValue[2]}`);
          dataNasterii = (`${century}${cnpValue[1]}${cnpValue[2]}-${cnpValue[3]}${cnpValue[4]}-${cnpValue[5]}${cnpValue[6]}`);
        }

        // console.log('CNP valid: ', dataNasterii);
        const ppp = moment(dataNasterii, 'YYYY-MM-DD').toDate();
        this.setState({ birthDate: ppp, dataEditable: false, }, () => {
          this.calcEligible();
        });
      });
    } else {
      // console.log('caz special');
      this.setState({ dataEditable: true, });
    }

    this.setState({ cnpError: cnpErrors, });
  }

  handleBtnClick(e) {
    const { name, } = e.target;
    const split = name.split('-');
    if (split[0] === 'habitat') {
      this.setState({ habitat: split[1], }, () => {
        this.handleVulnerable();
      });
    } else {
      this.setState({ [split[0]]: split[1] === 'DA', });
    }
  }

  handleVulnerable() {
    const { habitat, vulnerableSelected, } = this.state;
    if (habitat === 'Rural') {
      const newVulnerableSelected = vulnerableSelected ? vulnerableSelected.slice() : [];
      if (typeof newVulnerableSelected.find(elem => elem.value === 'din_mediul_rural') === 'undefined') {
        newVulnerableSelected.push({ value: 'din_mediul_rural', label: 'Femei din mediul rural', });
        this.setState({ vulnerableSelected: newVulnerableSelected, });
      }
    } else {
      const newVulnerableSelected = vulnerableSelected ? vulnerableSelected.filter(elem => elem.value !== 'din_mediul_rural') : [];
      this.setState({ vulnerableSelected: newVulnerableSelected, });
    }
  }

  handleGenderClick(e) {
    const { name, } = e.target;
    this.setState({
      gender: name,
      eligible: name === 'F',
    });
  }

  mobilizationDateChange(data) {
    this.setState({ mobilizationDate: data, }, () => {
      this.calcEligible();
    });
  }

  birthDateChange(data) {
    this.setState({ birthDate: data, }, () => {
      // calc eligibil
      this.calcEligible();
    });
  }

  calcEligible() {
    const { birthDate, mobilizationDate, } = this.state;
    if (birthDate === '' || mobilizationDate === '') return;
    const dataNasterii = moment(birthDate).format('YYYY-MM-DD');
    // console.log('data nasterii: ', dataNasterii);
    const dif = moment(mobilizationDate).diff(dataNasterii, 'years');
    this.setState({ eligible: !!((dif > 24 && dif < 65)), });
  }

  handleSelect(selected, extra) {
    const { name, } = extra;
    const { habitat, } = this.state;
    if (name === 'vulnerableSelected') {
      if (habitat === 'Urban') {
        selected = selected.filter(elem => elem.value !== 'din_mediul_rural');
      }
    }
    this.setState({ [name]: selected, }, () => {
      this.validateField(name, selected);
      if (name === 'vulnerableSelected') {
        this.setState({ vulnerability: selected.length > 0, });
      }
    });
  }

  handleSelectDisadvantaged(selected) {
    const filterArr = selected.filter(a => a.value === 'niciuna_din_optiuni');
    if (filterArr.length > 0) {
      this.setState({ disadvantagedSelected: filterArr, disadvantaged: false, }, () => {
        this.validateField('disadvantagedSelected', selected);
      });
    } else {
      this.setState({
        disadvantagedSelected: selected,
        disadvantaged: selected.length > 0,
      }, () => {
        this.validateField('disadvantagedSelected', selected);
      });
    }
  }

  handleActiveCheck() {
    const { active, } = this.state;
    this.setState({ active: !active, });
  }

  cancel() {
    const { navigate, } = this.props;
    navigate(-1);
  }

  deleteFile(e) {
    this.setState({ files: [], });
  }

  calcDisabled() {
    const {
      lastName, lastNameError,
      firstName, firstNameError,
      cnpError,
      populationCategorySelected,
      educationSelected,
      disadvantagedSelected,
      mobilizationDate,
      phoneError, emailError,
      addresses,
      dataEditable,
      birthDate,
    } = this.state;
    if (lastName === '' || lastNameError) return true;
    if (firstName === '' || firstNameError) return true;
    if (cnpError.length > 0) return true;
    if (populationCategorySelected === null || educationSelected === null || disadvantagedSelected === null) return true;
    if (mobilizationDate === '' || phoneError || emailError) return true;
    if (addresses.length === 0) return true;
    if (dataEditable && birthDate === '') return true;
  }

  render() {
    const {
      id, active,
      lastName, lastNameError,
      firstName, firstNameError,
      cnp, cnpError,
      identityCardSeries, identityCardSeriesError,
      identityCardNumber, identityCardNumberError,
      populationCategorySelected, populationCategoryOptions,
      educationSelected, populationError, educationOptions,
      disadvantagedSelected, disadvantagedError, disadvantagedOptions,
      vulnerableSelected, vulnerableOptions,
      mobilizationDate,
      birthDate,
      gender,
      habitat,
      disadvantaged,
      vulnerability,
      eligible,
      phone, phoneError,
      email, emailError,
      addresses,
      dataEditable,
      saving,
      error,
    } = this.state;

    // const files = this.state.files.map(file => (
    //   <li className="d-flex" key={file.name}>
    //     {file.name}
    //     <div id={file.name} className="ml-auto" onClick={this.deleteFile}>
    //       <div style={{ pointerEvents: 'none', }}>
    //         <FontAwesomeIcon icon={faTrash} />
    //       </div>
    //     </div>
    //   </li>
    // ));

    const btnDisabled = this.calcDisabled();
    return (
      <Container fluid className={`d-flex ${saving ? 'no-events' : ''}`}>
        {
          error && (
            <div className="position-fixed w-100" style={{ zIndex: 99, }}>
              <UncontrolledAlert className="login-alert" color="danger">
                {error}
              </UncontrolledAlert>
            </div>
          )
        }
        <Row className="justify-content-center w-100">
          <Col sm={10}>
            <Card className="mt-3">
              <CardHeader>
                <h3 className="mb-0">Creare sau editare Beneficiar</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6} className="pr-4">
                    <FormGroup row>
                      <Label sm={3}>Nume</Label>
                      <Col sm={9}>
                        <Input
                          required
                          type="text"
                          name="lastName"
                          className={`${(lastName === '' || lastNameError) && 'invalid'} `}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          value={lastName}
                        />
                        { lastName === '' && lastNameError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Prenume</Label>
                      <Col sm={9}>
                        <Input
                          required
                          type="text"
                          name="firstName"
                          className={`${(firstName === '' || firstNameError) && 'invalid'} `}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          value={firstName}
                        />
                        { firstName === '' && firstNameError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>CNP</Label>
                      <Col sm={9}>
                        <Input
                          required
                          type="text"
                          name="cnp"
                          className={`${(cnp === '' || cnpError.length > 0) && 'invalid'} `}
                          onChange={this.handleCnpChange}
                          onBlur={this.handleCnpChange}
                          value={cnp}
                        />
                        <div className="d-flex flex-column">
                          { cnpError && (
                            cnpError.map(a => <p key={a} className="error text-danger mt-1 mb-1">{a}</p>)
                          )}
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Seria</Label>
                      <Col sm={4}>
                        <Input
                          required
                          type="text"
                          name="identityCardSeries"
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          value={identityCardSeries}
                        />
                        { identityCardSeriesError && <p className="error text-danger mt-1 mb-1">Acest câmp nu poate fi mai lung de 2 caractere.</p>}
                      </Col>
                      <Label sm={1}>Nr</Label>
                      <Col sm={4}>
                        <Input
                          required
                          value={identityCardNumber}
                          type="text"
                          name="identityCardNumber"
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                        />
                        { identityCardNumberError && <p className="error text-danger mt-1 mb-1">Acest câmp nu poate fi mai lung de 6 caractere.</p>}
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label sm={3}>Data nașterii</Label>
                      {(!cnpError || cnpError.length === 0) && dataEditable ? (
                        <>
                          <Col sm={7} className="pr-0">
                            <Input
                              defaultValue={birthDate ? moment(birthDate).format('DD-MM-YYYY') : ''}
                              type="text"
                              className={`form-control bg-white ${(dataEditable && birthDate === '') ? 'invalid' : ''}`}
                              id=""
                              disabled
                              placeholder="dd-mm-yyyy"
                            />
                          </Col>
                          <Col sm={2} className="pl-0">
                            <DatePicker
                              locale="ro"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              selected={birthDate}
                              onChange={this.birthDateChange}
                              customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                            />
                          </Col>
                        </>
                      ) : (
                        <Col sm={9}>
                          <Input
                            disabled
                            type="text"
                            name="birthDate"
                            defaultValue={birthDate ? moment(birthDate).format('DD-MM-YYYY') : ''}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Gen</Label>
                      <Col sm={9}>
                        {(!cnpError || cnpError.length === 0) && dataEditable && (
                          <ButtonGroup size="sm">
                            <Button onClick={this.handleGenderClick} name="M" color="primary" outline={gender !== 'M'}>M</Button>
                            <Button onClick={this.handleGenderClick} name="F" color="primary" outline={gender !== 'F'}>F</Button>
                          </ButtonGroup>
                        )}
                        {
                          !dataEditable && (
                            <ButtonGroup className="no-events" size="sm">
                              <Button name="gender-M" color="primary no-events" outline={gender !== 'M'}>M</Button>
                              <Button name="gender-F" color="primary no-events" outline={gender !== 'F'}>F</Button>
                            </ButtonGroup>
                          )
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Mediu</Label>
                      <Col sm={9}>
                        <ButtonGroup className="" size="sm">
                          <Button
                            onClick={this.handleBtnClick}
                            name="habitat-Rural"
                            color="primary"
                            outline={habitat !== 'Rural'}
                          >
                            Rural
                          </Button>
                          <Button
                            onClick={this.handleBtnClick}
                            name="habitat-Urban"
                            color="primary"
                            outline={habitat !== 'Urban'}
                          >
                            Urban
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label sm={3}>Situația pe piața forței de muncă</Label>
                      <Col sm={9}>
                        <FormGroup>
                          <Select
                            value={populationCategorySelected}
                            className={(populationCategorySelected === null) ? 'select-invalid' : 'select-valid'}
                            classNamePrefix="rolesSelect"
                            placeholder=""
                            isClearable
                            name="populationCategorySelected"
                            options={populationCategoryOptions}
                            onChange={this.handleSelect}
                          />
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={6} className="pl-4">
                    <FormGroup row>
                      <Label sm={3}>Telefon</Label>
                      <Col sm={9}>
                        <Input
                          required
                          type="text"
                          name="phone"
                          className={`${(phone !== '' && phoneError) && 'invalid'} `}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          value={phone}
                        />
                        { phoneError && <p className="error text-danger mt-1 mb-1">{phoneError}</p>}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Email</Label>
                      <Col sm={9}>
                        <Input
                          required
                          type="text"
                          name="email"
                          className={`${(email !== '' && emailError) && 'invalid'} `}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          value={email}
                        />
                        { emailError && <p className="error text-danger mt-1 mb-1">{emailError}</p>}
                      </Col>
                    </FormGroup>
                    <PatientAddresses
                      isRequired
                      addresses={addresses}
                      handleAddressChange={this.handleAddressChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="pr-4">
                    <FormGroup row className="align-items-center">
                      <Label sm={3}>Nivel de educație</Label>
                      <Col sm={9}>
                        <Select
                          value={educationSelected}
                          className={educationSelected === null ? 'select-invalid' : 'select-valid'}
                          classNamePrefix="rolesSelect"
                          placeholder=""
                          isClearable
                          name="educationSelected"
                          options={educationOptions}
                          onChange={this.handleSelect}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label sm={3}>Persoană dezavantajată</Label>
                      <Col sm={9}>
                        <Select
                          isMulti
                          value={disadvantagedSelected}
                          className={(disadvantagedSelected === null || disadvantagedSelected.length === 0) ? 'select-invalid' : 'select-valid'}
                          classNamePrefix="rolesSelect"
                          placeholder=""
                          isClearable
                          name="disadvantagedSelected"
                          options={disadvantagedOptions}
                          onChange={this.handleSelectDisadvantaged}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label sm={3}>Grupuri vulnerabile</Label>
                      <Col sm={9}>
                        <Select
                          isMulti
                          value={vulnerableSelected}
                          className="select-valid"
                          classNamePrefix="rolesSelect"
                          placeholder=""
                          isClearable
                          name="vulnerableSelected"
                          options={vulnerableOptions}
                          onChange={this.handleSelect}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label sm={3}>Data introducere beneficiar</Label>
                      <Col sm={7} className="pr-0">
                        <Input
                          defaultValue={mobilizationDate ? moment(mobilizationDate).format('DD-MM-YYYY') : ''}
                          type="text"
                          className={`form-control bg-white ${(mobilizationDate === '') && 'invalid'} `}
                          id=""
                          disabled
                          placeholder="dd-mm-yyyy"
                        />
                      </Col>
                      <Col sm={2} className="pl-0">
                        <DatePicker
                          minDate={new Date('01-01-2021')}
                          maxDate={new Date()}
                          locale="ro"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          selected={mobilizationDate}
                          onChange={this.mobilizationDateChange}
                          customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={6} className="pl-4">
                    <FormGroup row className="align-items-center">
                      <Label sm={4}>Eligibil</Label>
                      <Col sm={7}>
                        <ButtonGroup size="sm" className="no-events">
                          <Button name="eligible-DA" color="primary" outline={!eligible}>DA</Button>
                          <Button name="eligible-NU" color="primary" outline={eligible}>NU</Button>
                        </ButtonGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label sm={4}>Persoană dezavantajată</Label>
                      <Col sm={7}>
                        <ButtonGroup size="sm" className="no-events">
                          <Button name="disadvantaged-DA" color="primary" outline={!disadvantaged}>DA</Button>
                          <Button name="disadvantaged-NU" color="primary" outline={disadvantaged}>NU</Button>
                        </ButtonGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label sm={4}>Persoană vulnerabilă</Label>
                      <Col sm={7}>
                        <ButtonGroup size="sm" className="no-events">
                          <Button name="vulnerability-DA" color="primary" outline={!vulnerability}>DA</Button>
                          <Button name="vulnerability-NU" color="primary" outline={vulnerability}>NU</Button>
                        </ButtonGroup>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row> */}
                {/*  <Label sm={3}>Atașamente</Label> */}

                {/* </Row> */}
                {/* <Row className="file-upload"> */}

                {/*  <Dropzone onDrop={this.onDrop}> */}
                {/*    {({ getRootProps, getInputProps, }) => ( */}
                {/*      <> */}
                {/*        { */}
                {/*          files.length === 0 && ( */}
                {/*          <div {...getRootProps({ className: 'dropzone', })}> */}
                {/*            <div className="d-flex justify-content-center align-items-center h-100"> */}
                {/*              <div className="border p-2 d-inline-flex"> */}
                {/*                <FontAwesomeIcon icon={faUpload} /> */}
                {/*              </div> */}
                {/*              <input {...getInputProps()} /> */}
                {/*              <p className="mb-0 ml-2">Click pentru încărcare fișiere</p> */}
                {/*            </div> */}
                {/*          </div> */}
                {/*          ) */}
                {/*        } */}
                {/*        { */}
                {/*          files.length > 0 && ( */}
                {/*            <div className="d-flex w-100"> */}
                {/*              <div className="col w-100 pr-5">{files}</div> */}
                {/*              <div {...getRootProps({ className: 'dropzone', })} className="border p-2 d-inline-flex"> */}
                {/*                <FontAwesomeIcon icon={faUpload} /> */}
                {/*                <input {...getInputProps()} /> */}
                {/*              </div> */}
                {/*            </div> */}
                {/*          ) */}
                {/*        } */}
                {/*      </> */}
                {/*    )} */}
                {/*  </Dropzone> */}
                {/* </Row> */}
                <Row className="mt-3">
                  <Col md={6}>
                    {/* { */}
                    {/*  id !== null && ( */}
                    {/*    <FormGroup check> */}
                    {/*      <Input */}
                    {/*        // defaultChecked={active} */}
                    {/*        checked={active} */}
                    {/*        type="checkbox" */}
                    {/*        value={active} */}
                    {/*        name="active" */}
                    {/*        onChange={this.handleActiveCheck} */}
                    {/*      /> */}
                    {/*      <Label>Stare</Label> */}
                    {/*    </FormGroup> */}
                    {/*  ) */}
                    {/* } */}
                  </Col>
                  <Col md={6} className="text-right">
                    <Button
                      onClick={this.cancel}
                      id="cancel-save"
                      color="secondary"
                    >
                      <FontAwesomeIcon icon={faBan} />
                      <span className="ml-1">Anulare</span>
                    </Button>
                    <Button
                      id="save"
                      color="primary"
                      className={`ml-1 ${btnDisabled ? 'disabled no-events' : ''}`}
                      onClick={btnDisabled ? () => {} : this.handleSubmit}
                    >
                      <FontAwesomeIcon icon={faSave} />
                      <span className="ml-1">Salvare</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  patients: state.patients.patients,
  selectedPatient: state.patients.selectedPatient,
});

const mapDispatchToProps = dispatch => ({
  createPatient: data => dispatch(patientsOperations.createPatient(data)),
  updatePatient: data => dispatch(patientsOperations.updatePatient(data)),
  getSinglePatient: id => dispatch(patientsOperations.getSinglePatient(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientForm);

const calcPhone = arr => {
  const phoneElem = arr.filter(elem => elem.contactType.value === 'TELEFON')[0];
  return phoneElem && typeof phoneElem.label !== 'undefined' ? phoneElem.label : '';
};

const calcEmail = arr => {
  const emailElem = arr.filter(elem => elem.contactType.value === 'EMAIL')[0];
  return emailElem && typeof emailElem.label !== 'undefined' ? emailElem.label : '';
};
