/* eslint-disable no-undef */
import actions from './actions';

const getCasefiles = url => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getCasefiles(url))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getSingleCasefile = id => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getSingleCasefile(id))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getSingleFile = id => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getSingleFile(id))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const createCasefile = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.createCasefile(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const createFile = id => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.createFile(id))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const updateFile = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.updateFile(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const downloadFile = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.downloadFile(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const updateCasefile = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.updateCasefile(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

export default {
  createCasefile,
  updateCasefile,
  getCasefiles,
  getSingleCasefile,
  getSingleFile,
  createFile,
  updateFile,
  downloadFile,
};
