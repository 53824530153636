import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import {
  Login, Header, RequireAuth, ForgotPassword,
  Patient, Doctor,
  Indicatori, Raportare, RaportareOir, Organization,
  Nomen, Template, Office,
  Group, Mesaje, Reset, Role, Formulare, Users
} from './components/index';
import { OrganizationForm, OrganizationView } from './components/views/Organization/components';
import { UserForm, UserView } from './components/views/Utilizatori/components';
import { DoctorView, DoctorForm } from './components/views/Doctors/components';
import { PatientForm, PatientView } from './components/views/Patients/components';
import { CasefileCreateEdit, CasefileView } from './components/views/Casefiles/components';
import Casefiles from './components/views/Casefiles/Casefiles';
import SingleFileEdit from './components/views/Casefiles/components/SingleFileEdit';
import SingleFileView from './components/views/Casefiles/components/SingleFileView';
import NavigationWrapper from './components/NavigationWrapper';

import './index.scss';
import PasswordReset from './components/views/Login/PasswordReset';
import ErrorBoundary from './components/ErrorBoundary';
import Indicators from './components/views/Indicators/Indicators';

function App(props) {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Header props={props} />
        <Routes>
          {/* <Route exact path="/indicators" element={<RequireAuth><Indicatori /></RequireAuth>} /> */}
          {/* <Route exact path="/indicators" element={<RequireAuth><Indicators /></RequireAuth>} /> */}
          {/* <Route exact path="/reporting-oir" element={<RequireAuth><RaportareOIR /></RequireAuth>} /> */}
          <Route exact path="/reporting" element={<RequireAuth><Raportare /></RequireAuth>} />
          <Route exact path="/nomen" element={<RequireAuth><Nomen /></RequireAuth>} />
          <Route exact path="/template" element={<RequireAuth><Template /></RequireAuth>} />
          <Route exact path="/role" element={<RequireAuth><Role /></RequireAuth>} />
          <Route exact path="/group" element={<RequireAuth><Group /></RequireAuth>} />
          <Route exact path="/office" element={<RequireAuth><Office /></RequireAuth>} />
          <Route exact path="/form" element={<RequireAuth><Formulare /></RequireAuth>} />

          {/* indicators */}

          <Route
            exact
            path="/indicator"
            element={(
              <RequireAuth>
                <NavigationWrapper component={Indicators} />
              </RequireAuth>
            )}
          />

          <Route
            exact
            path="/reporting-oir"
            element={(
              <RequireAuth>
                <NavigationWrapper component={RaportareOir} />
              </RequireAuth>
            )}
          />

          {/* organizations */}

          <Route
            exact
            path="/organization/new"
            element={(
              <RequireAuth>
                <NavigationWrapper component={OrganizationForm} />
              </RequireAuth>
            )}
          />

          <Route
            exact
            path="/organization/:id/view"
            element={(
              <RequireAuth>
                <NavigationWrapper component={OrganizationView} />
              </RequireAuth>
            )}
          />

          <Route
            exact
            path="/organization/:id/edit"
            element={(
              <RequireAuth>
                <NavigationWrapper component={OrganizationForm} />
              </RequireAuth>
            )}
          />

          <Route
            exact
            path="/organization"
            element={(
              <RequireAuth>
                <NavigationWrapper component={Organization} />
              </RequireAuth>
            )}
          />

          {/* users */}

          <Route
            exact
            path="/user/new"
            element={(
              <RequireAuth>
                <NavigationWrapper component={UserForm} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/user/:id/view"
            element={(
              <RequireAuth>
                <NavigationWrapper component={UserView} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/user/:id/edit"
            element={(
              <RequireAuth>
                <NavigationWrapper component={UserForm} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/user"
            element={(
              <RequireAuth>
                <NavigationWrapper component={Users} />
              </RequireAuth>
            )}
          />

          {/* doctors */}
          <Route
            exact
            path="/doctor/new"
            element={(
              <RequireAuth>
                <NavigationWrapper component={DoctorForm} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/doctor/:id/view"
            element={(
              <RequireAuth>
                <NavigationWrapper component={DoctorView} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/doctor/:id/edit"
            element={(
              <RequireAuth>
                <NavigationWrapper component={DoctorForm} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/doctor"
            element={(
              <RequireAuth>
                <NavigationWrapper component={Doctor} />
              </RequireAuth>
            )}
          />

          {/* patient */}
          <Route
            exact
            path="/patient/new"
            element={(
              <RequireAuth>
                <NavigationWrapper component={PatientForm} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/patient/:id/view"
            element={(
              <RequireAuth>
                <NavigationWrapper component={PatientView} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/patient/:id/edit"
            element={(
              <RequireAuth>
                <NavigationWrapper component={PatientForm} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/patient"
            element={(
              <RequireAuth>
                <NavigationWrapper component={Patient} />
              </RequireAuth>
            )}
          />

          {/* dosare  casefile */}

          <Route
            exact
            path="/casefile/:id/view"
            element={(
              <RequireAuth>
                <NavigationWrapper component={CasefileView} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/casefile/:id/edit"
            element={(
              <RequireAuth>
                <NavigationWrapper component={CasefileCreateEdit} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/casefile"
            element={(
              <RequireAuth>
                <NavigationWrapper component={Casefiles} />
              </RequireAuth>
            )}
          />

          <Route
            exact
            path="/file/:id/edit"
            element={(
              <RequireAuth>
                <NavigationWrapper component={SingleFileEdit} />
              </RequireAuth>
            )}
          />

          <Route
            exact
            path="/file/:id/view"
            element={(<RequireAuth><NavigationWrapper component={SingleFileView} /></RequireAuth>)}
          />

          <Route exact path="/check/change-pass" element={<RequireAuth><Reset /></RequireAuth>} />
          <Route exact path="/notifications" element={<RequireAuth><Mesaje /></RequireAuth>} />
          <Route path="/check" element={<Login />} />
          <Route path="/check/send-reset-mail" element={<ForgotPassword />} />
          <Route path="/auth/passwordreset/:id" element={<PasswordReset />} />

          <Route
            path="/"
            element={<Navigate to="/patient" replace />}
          />
          <Route
            path="*"
            element={<Navigate to="/patient" replace />}
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
